<template>
  <div class="page-content-stock">
    <div class="header">
      <h3>PAC Ballon</h3>
    </div>
    <hr />
    <div class="content-pac-ballon">
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <div v-else class="content-tab-pac-ballon">
        <div>
          <b-table-simple
            small
            show-empty
            id="my-table2"
            :items="getStocksPlanifier"
            :sticky-header="true"
            empty-text="Il n'y a aucun enregistrement à afficher"
            empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
          >
            <b-thead>
              <b-tr>
                <b-th>Semaine </b-th>
                <b-th>Nb Pose PAC </b-th>
                <b-th>Q PAC</b-th>
                <b-th>PAC - Type</b-th>
                <b-th>PAC - Marque</b-th>
                <b-th>PAC - Puissance</b-th>
                <b-th>PAC - Alimentation</b-th>
                <b-th class="left-border">Nb Pose Ballons</b-th>
                <b-th>Q Ballons</b-th>
                <b-th>Ballon - Type</b-th>
                <b-th>Ballon - Capacité</b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="(item, index) in getStocksPlanifier.travaux_planifies"
              :key="index"
            >
              <b-tr :class="{ timeline: getStockProcessing }">
                <b-td
                  :rowspan="
                    computedLigneAssocaition(
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.chauffage &&
                        item.chauffage_associations_semaine.chauffage.length
                        ? item.chauffage_associations_semaine.chauffage.length
                        : 0,
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.ballon &&
                        item.chauffage_associations_semaine.ballon.length
                        ? item.chauffage_associations_semaine.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.chauffage &&
                            item.chauffage_associations_semaine.chauffage.length
                            ? item.chauffage_associations_semaine.chauffage
                                .length
                            : 0,
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.ballon &&
                            item.chauffage_associations_semaine.ballon.length
                            ? item.chauffage_associations_semaine.ballon.length
                            : 0
                        )
                      : 1
                  "
                >
                  {{ item.debut_semaine }} <br />
                  -<br />{{ item.fin_semaine }}
                </b-td>
                <b-td
                  :rowspan="
                    computedLigneAssocaition(
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.chauffage &&
                        item.chauffage_associations_semaine.chauffage.length
                        ? item.chauffage_associations_semaine.chauffage.length
                        : 0,
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.ballon &&
                        item.chauffage_associations_semaine.ballon.length
                        ? item.chauffage_associations_semaine.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.chauffage &&
                            item.chauffage_associations_semaine.chauffage.length
                            ? item.chauffage_associations_semaine.chauffage
                                .length
                            : 0,
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.ballon &&
                            item.chauffage_associations_semaine.ballon.length
                            ? item.chauffage_associations_semaine.ballon.length
                            : 0
                        )
                      : 1
                  "
                >
                  {{
                    item.nb_rdv_chauffage_semaine
                      ? item.nb_rdv_chauffage_semaine
                      : '-'
                  }}</b-td
                >
                <b-td>
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.chauffage &&
                    item.chauffage_associations_semaine.chauffage.length &&
                    item.chauffage_associations_semaine.chauffage[0] &&
                    item.chauffage_associations_semaine.chauffage[0]
                      .nombre_occurence
                      ? item.chauffage_associations_semaine.chauffage[0]
                          .nombre_occurence
                      : '-'
                  }}
                </b-td>
                <b-td rowspan="1" colspan="1">
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.chauffage &&
                    item.chauffage_associations_semaine.chauffage.length &&
                    item.chauffage_associations_semaine.chauffage[0] &&
                    item.chauffage_associations_semaine.chauffage[0].association
                      ? item.chauffage_associations_semaine.chauffage[0]
                          .association.type_chauffage
                      : '-'
                  }}</b-td
                >
                <b-td rowspan="1" colspan="1">
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.chauffage &&
                    item.chauffage_associations_semaine.chauffage.length &&
                    item.chauffage_associations_semaine.chauffage[0] &&
                    item.chauffage_associations_semaine.chauffage[0].association
                      ? item.chauffage_associations_semaine.chauffage[0]
                          .association.marque_chauffage
                      : '-'
                  }}</b-td
                >
                <b-td rowspan="1" colspan="1">
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.chauffage &&
                    item.chauffage_associations_semaine.chauffage.length &&
                    item.chauffage_associations_semaine.chauffage[0] &&
                    item.chauffage_associations_semaine.chauffage[0].association
                      ? item.chauffage_associations_semaine.chauffage[0]
                          .association.puissance_chauffage
                      : '-'
                  }}</b-td
                >
                <b-td rowspan="1" colspan="1">
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.chauffage &&
                    item.chauffage_associations_semaine.chauffage.length &&
                    item.chauffage_associations_semaine.chauffage[0] &&
                    item.chauffage_associations_semaine.chauffage[0].association
                      ? item.chauffage_associations_semaine.chauffage[0]
                          .association.alimentation_chauffage
                      : '-'
                  }}</b-td
                >
                <b-td
                  :rowspan="
                    computedLigneAssocaition(
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.chauffage &&
                        item.chauffage_associations_semaine.chauffage.length
                        ? item.chauffage_associations_semaine.chauffage.length
                        : 0,
                      item.chauffage_associations_semaine &&
                        item.chauffage_associations_semaine.ballon &&
                        item.chauffage_associations_semaine.ballon.length
                        ? item.chauffage_associations_semaine.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.chauffage &&
                            item.chauffage_associations_semaine.chauffage.length
                            ? item.chauffage_associations_semaine.chauffage
                                .length
                            : 0,
                          item.chauffage_associations_semaine &&
                            item.chauffage_associations_semaine.ballon &&
                            item.chauffage_associations_semaine.ballon.length
                            ? item.chauffage_associations_semaine.ballon.length
                            : 0
                        )
                      : 1
                  "
                  colspan="1"
                  class="left-border"
                  >{{
                    item.nb_rdv_ballon_semaine
                      ? item.nb_rdv_ballon_semaine
                      : '-'
                  }}</b-td
                >
                <b-td rowspan="1" colspan="1">
                  {{
                    item.chauffage_associations_semaine &&
                    item.chauffage_associations_semaine.ballon &&
                    item.chauffage_associations_semaine.ballon.length &&
                    item.chauffage_associations_semaine.ballon[0] &&
                    item.chauffage_associations_semaine.ballon[0]
                      .nombre_occurence
                      ? item.chauffage_associations_semaine.ballon[0]
                          .nombre_occurence
                      : '-'
                  }}
                </b-td>
                <b-td rowspan="1" colspan="1">{{
                  item.chauffage_associations_semaine &&
                  item.chauffage_associations_semaine.ballon &&
                  item.chauffage_associations_semaine.ballon.length &&
                  item.chauffage_associations_semaine.ballon[0] &&
                  item.chauffage_associations_semaine.ballon[0].association
                    ? item.chauffage_associations_semaine.ballon[0].association
                        .type_ballon
                    : '-'
                }}</b-td>
                <b-td rowspan="1" colspan="1">{{
                  item.chauffage_associations_semaine &&
                  item.chauffage_associations_semaine.ballon &&
                  item.chauffage_associations_semaine.ballon.length &&
                  item.chauffage_associations_semaine.ballon[0] &&
                  item.chauffage_associations_semaine.ballon[0].association
                    ? item.chauffage_associations_semaine.ballon[0].association
                        .capacite_ballon
                    : '-'
                }}</b-td>
              </b-tr>
              <template
                v-if="
                  computedLigneAssocaition(
                    item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length
                      ? item.chauffage_associations_semaine.chauffage.length - 1
                      : 0,
                    item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.ballon &&
                      item.chauffage_associations_semaine.ballon.length
                      ? item.chauffage_associations_semaine.ballon.length - 1
                      : 0
                  ) != 0
                "
              >
                <b-tr
                  v-for="obj in computedLigneAssocaition(
                    item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length
                      ? item.chauffage_associations_semaine.chauffage.length - 1
                      : 0,
                    item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.ballon &&
                      item.chauffage_associations_semaine.ballon.length
                      ? item.chauffage_associations_semaine.ballon.length - 1
                      : 0
                  )"
                  :key="obj"
                  :class="{ timeline: getStockProcessing }"
                >
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length &&
                      item.chauffage_associations_semaine.chauffage[obj] &&
                      item.chauffage_associations_semaine.chauffage[obj]
                        .nombre_occurence
                        ? item.chauffage_associations_semaine.chauffage[obj]
                            .nombre_occurence
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length &&
                      item.chauffage_associations_semaine.chauffage[obj] &&
                      item.chauffage_associations_semaine.chauffage[obj]
                        .association
                        ? item.chauffage_associations_semaine.chauffage[obj]
                            .association.type_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length &&
                      item.chauffage_associations_semaine.chauffage[obj] &&
                      item.chauffage_associations_semaine.chauffage[obj]
                        .association
                        ? item.chauffage_associations_semaine.chauffage[obj]
                            .association.marque_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length &&
                      item.chauffage_associations_semaine.chauffage[obj] &&
                      item.chauffage_associations_semaine.chauffage[obj]
                        .association
                        ? item.chauffage_associations_semaine.chauffage[obj]
                            .association.puissance_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.chauffage &&
                      item.chauffage_associations_semaine.chauffage.length &&
                      item.chauffage_associations_semaine.chauffage[obj] &&
                      item.chauffage_associations_semaine.chauffage[obj]
                        .association
                        ? item.chauffage_associations_semaine.chauffage[obj]
                            .association.alimentation_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.ballon &&
                      item.chauffage_associations_semaine.ballon.length &&
                      item.chauffage_associations_semaine.ballon[obj] &&
                      item.chauffage_associations_semaine.ballon[obj]
                        .nombre_occurence &&
                      computedLigne(
                        item.chauffage_associations_semaine.ballon.length - 1,
                        obj
                      )
                        ? item.chauffage_associations_semaine.ballon[obj]
                            .nombre_occurence
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.ballon &&
                      item.chauffage_associations_semaine.ballon.length &&
                      item.chauffage_associations_semaine.ballon[obj] &&
                      item.chauffage_associations_semaine.ballon[obj]
                        .association &&
                      computedLigne(
                        item.chauffage_associations_semaine.ballon.length - 1,
                        obj
                      )
                        ? item.chauffage_associations_semaine.ballon[obj]
                            .association.type_ballon
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.chauffage_associations_semaine &&
                      item.chauffage_associations_semaine.ballon &&
                      item.chauffage_associations_semaine.ballon.length &&
                      item.chauffage_associations_semaine.ballon[obj] &&
                      item.chauffage_associations_semaine.ballon[obj]
                        .association &&
                      computedLigne(
                        item.chauffage_associations_semaine.ballon.length - 1,
                        obj
                      )
                        ? item.chauffage_associations_semaine.ballon[obj]
                            .association.capacite_ballon
                        : '-'
                    }}
                  </td>
                </b-tr>
              </template>
            </b-tbody>
            <b-tbody>
              <!-- TOTAL -->
              <tr
                :class="{ timeline: getStockProcessing }"
                v-if="getStocksPlanifier && getStocksPlanifier.total"
              >
                <td class="bold">Total</td>
                <td colspan="6">
                  {{
                    getStocksPlanifier && getStocksPlanifier.total
                      ? getStocksPlanifier.total.total_rdv_chauffage
                      : '-'
                  }}
                </td>

                <td colspan="4" class="left-border">
                  {{
                    getStocksPlanifier && getStocksPlanifier.total
                      ? getStocksPlanifier.total.total_rdv_ballon
                      : '-'
                  }}
                </td>
              </tr>
              <!-- TABLE TRAVAUX A PLANIFIER -->
              <tr
                class="top-border"
                :class="{ timeline: getStockAPlanifierProcessing == false }"
              >
                <td
                  :rowspan="
                    computedLigneAssocaition(
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage.length
                        : 0,
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage
                              .length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.chauffage
                                .length
                            : 0,
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon.length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.ballon
                                .length
                            : 0
                        )
                      : 1
                  "
                  class="bold"
                >
                  Total à planifier
                </td>
                <td
                  :rowspan="
                    computedLigneAssocaition(
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage.length
                        : 0,
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage
                              .length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.chauffage
                                .length
                            : 0,
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon.length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.ballon
                                .length
                            : 0
                        )
                      : 1
                  "
                >
                  {{
                    getStocksAPlanifier &&
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .nb_rdv_chauffage_a_planifier
                      ? getStocksAPlanifier.travaux_a_planifier
                          .nb_rdv_chauffage_a_planifier
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0]
                      .nombre_occurence
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage[0]
                          .nombre_occurence
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0]
                      .association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage[0]
                          .association.type_chauffage
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0]
                      .association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage[0]
                          .association.marque_chauffage
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0]
                      .association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage[0]
                          .association.puissance_chauffage
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.chauffage[0]
                      .association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage[0]
                          .association.alimentation_chauffage
                      : '-'
                  }}
                </td>
                <td
                  :rowspan="
                    computedLigneAssocaition(
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage.length
                        : 0,
                      getStocksAPlanifier &&
                        getStocksAPlanifier.travaux_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon &&
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon.length
                        : 0
                    ) != 0
                      ? computedLigneAssocaition(
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.chauffage
                              .length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.chauffage
                                .length
                            : 0,
                          getStocksAPlanifier &&
                            getStocksAPlanifier.travaux_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon &&
                            getStocksAPlanifier.travaux_a_planifier
                              .chauffage_associations_a_planifier.ballon.length
                            ? getStocksAPlanifier.travaux_a_planifier
                                .chauffage_associations_a_planifier.ballon
                                .length
                            : 0
                        )
                      : 1
                  "
                  class="left-border"
                >
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .nb_rdv_ballon_a_planifier
                      ? getStocksAPlanifier.travaux_a_planifier
                          .nb_rdv_ballon_a_planifier
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0]
                      .nombre_occurence
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon[0]
                          .nombre_occurence
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0].association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon[0]
                          .association.type_ballon
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon.length &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0] &&
                    getStocksAPlanifier.travaux_a_planifier
                      .chauffage_associations_a_planifier.ballon[0].association
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon[0]
                          .association.capacite_ballon
                      : '-'
                  }}
                </td>
              </tr>
              <template
                v-if="
                  computedLigneAssocaition(
                    getStocksAPlanifier &&
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage.length -
                          1
                      : 0,
                    getStocksAPlanifier &&
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon.length
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length - 1
                      : 0
                  ) != 0
                "
              >
                <b-tr
                  v-for="obj in computedLigneAssocaition(
                    getStocksAPlanifier &&
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.chauffage.length -
                          1
                      : 0,
                    getStocksAPlanifier &&
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon.length
                      ? getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length - 1
                      : 0
                  )"
                  :key="obj"
                  :class="{ timeline: getStockAPlanifierProcessing == false }"
                >
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj]
                        .nombre_occurence
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage[obj]
                            .nombre_occurence
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj]
                        .association
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage[obj]
                            .association.type_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj]
                        .association
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage[obj]
                            .association.marque_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj]
                        .association
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage[obj]
                            .association.puissance_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.chauffage[obj]
                        .association
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.chauffage[obj]
                            .association.alimentation_chauffage
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj]
                        .nombre_occurence &&
                      computedLigne(
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length - 1,
                        obj
                      )
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon[obj]
                            .nombre_occurence
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj]
                        .association &&
                      computedLigne(
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length - 1,
                        obj
                      )
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon[obj]
                            .association.type_ballon
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      getStocksAPlanifier.travaux_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon.length &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj] &&
                      getStocksAPlanifier.travaux_a_planifier
                        .chauffage_associations_a_planifier.ballon[obj]
                        .association &&
                      computedLigne(
                        getStocksAPlanifier.travaux_a_planifier
                          .chauffage_associations_a_planifier.ballon.length - 1,
                        obj
                      )
                        ? getStocksAPlanifier.travaux_a_planifier
                            .chauffage_associations_a_planifier.ballon[obj]
                            .association.capacite_ballon
                        : '-'
                    }}
                  </td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PacBallon',
  data() {
    return {
      initLoading: true
    }
  },
  methods: {
    ...mapActions(['fetchStocksPlannifier', 'fetchStocksAplannifier'])
  },
  computed: {
    ...mapGetters([
      'getStockErrorStocks',
      'getStockProcessing',
      'getStockAPlanifierProcessing',
      'getStocksAPlanifier',
      'getStocksPlanifier'
    ]),
    computedLigne() {
      return function(ballon, nbrLigneChauffage) {
        if (nbrLigneChauffage <= ballon) {
          return true
        }
        return false
      }
    },
    computedLigneAssocaition() {
      return function(chauffage, ballon) {
        if (chauffage == 0 && ballon != 0) {
          return ballon
        } else if (chauffage != 0 && ballon == 0) {
          return chauffage
        } else if (ballon != 0 && chauffage != 0) {
          if (chauffage == ballon) {
            return chauffage
          } else if (chauffage >= ballon) {
            return chauffage
          } else if (chauffage <= ballon) {
            return ballon
          }
        }
      }
    }
  },
  async mounted() {
    await this.fetchStocksPlannifier('pac')
    this.fetchStocksAplannifier('pac')
    this.initLoading = false
  }
}
</script>
<style lang="scss">
.content-tab-pac-ballon {
  table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 0;
    border-top: 9px solid #dee2e6;
    border-bottom: 1px solid #b9babb;
    margin-top: -8px;
    font-size: 13px;
    text-align: center;

    tr {
      &:hover {
        th {
          background: #dee2e6;
        }
      }
    }
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(0.3rem / 2) center;
    padding-left: calc(1.3rem + 0.65em);
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    // padding: 2px;
    border: 1px solid #dee2e6;
    vertical-align: middle;
  }
  .b-table-sticky-header {
    overflow-y: hidden;
    max-height: 100%;
  }
  .table tbody + tbody {
    border-top: 1px solid #dee2e6;
  }
  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    font-size: 12px;
    color: #212529;
    background-color: #f8f8f8;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    top: -4px;
    vertical-align: middle;
    width: 72px;
  }
}
</style>
<style scoped lang="scss">
.page-content-stock {
  margin: 60px;
  padding: 15px;
  min-height: 80%;
  background-color: #f8f8f8;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .content-pac-ballon {
    .content-tab-pac-ballon {
      .bold {
        font-weight: bold;
      }
      .left-border {
        border-left: 2px solid #dee2e6;
      }
      .top-border {
        border-top: 2px solid #dee2e6;
      }
      .timeline {
        width: 100%;
        height: 100%;
        color: transparent;
        animation-duration: 1.8s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: transparent;
        background: linear-gradient(
          to right,
          #f6f6f6 0%,
          #dbdbdb 25%,
          #f6f6f6 50%
        );
        background-size: 1000px 640px;
        &.width {
          width: 220px;
          height: 250%;
        }
        &.height {
          height: 77px;
          width: 192px;
        }
      }

      @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0;
        }
        100% {
          background-position: 468px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .page-content-stock {
    margin: 11px;
    padding: 15px;
    min-height: 96%;
    background-color: #f8f8f8;
  }
}
</style>
